import { __decorate, __metadata } from 'tslib';
import { EventEmitter, Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, NgModule } from '@angular/core';
import { defineCustomElements } from '@sdc-wob-type-3/group-ui/dist/loader';

/**
 * @fileoverview added by tsickle
 * Generated from: directives/angular-component-lib/utils.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
const proxyInputs = (/**
 * @param {?} Cmp
 * @param {?} inputs
 * @return {?}
 */
(Cmp, inputs) => {
    /** @type {?} */
    const Prototype = Cmp.prototype;
    inputs.forEach((/**
     * @param {?} item
     * @return {?}
     */
    (item) => {
        Object.defineProperty(Prototype, item, {
            /**
             * @return {?}
             */
            get() {
                return this.el[item];
            },
            /**
             * @param {?} val
             * @return {?}
             */
            set(val) {
                this.z.runOutsideAngular((/**
                 * @return {?}
                 */
                () => (this.el[item] = val)));
            },
        });
    }));
});
/** @type {?} */
const proxyMethods = (/**
 * @param {?} Cmp
 * @param {?} methods
 * @return {?}
 */
(Cmp, methods) => {
    /** @type {?} */
    const Prototype = Cmp.prototype;
    methods.forEach((/**
     * @param {?} methodName
     * @return {?}
     */
    (methodName) => {
        Prototype[methodName] = (/**
         * @return {?}
         */
        function () {
            /** @type {?} */
            const args = arguments;
            return this.z.runOutsideAngular((/**
             * @return {?}
             */
            () => this.el[methodName].apply(this.el, args)));
        });
    }));
});
// manual hotfix for: https://github.com/ionic-team/stencil-ds-output-targets/issues/81
/** @type {?} */
const proxyOutputs = (/**
 * @param {?} instance
 * @param {?} el
 * @param {?} events
 * @return {?}
 */
(instance, el, events) => {
    events.forEach((/**
     * @param {?} eventName
     * @return {?}
     */
    (eventName) => (instance[eventName] = new EventEmitter())));
});
// tslint:disable-next-line: only-arrow-functions
/**
 * @param {?} opts
 * @return {?}
 */
function ProxyCmp(opts) {
    /** @type {?} */
    const decorator = (/**
     * @param {?} cls
     * @return {?}
     */
    function (cls) {
        if (opts.inputs) {
            proxyInputs(cls, opts.inputs);
        }
        if (opts.methods) {
            proxyMethods(cls, opts.methods);
        }
        return cls;
    });
    return decorator;
}

/**
 * @fileoverview added by tsickle
 * Generated from: directives/proxies.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
let GroupuiAccordion = class GroupuiAccordion {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiAccordion.decorators = [
    { type: Component, args: [{
                selector: 'groupui-accordion',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['expanded', 'first', 'headline', 'indentation', 'size']
            },] },
];
/** @nocollapse */
GroupuiAccordion.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiAccordion = __decorate([
    ProxyCmp({
        inputs: ['expanded', 'first', 'headline', 'indentation', 'size']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiAccordion);
let GroupuiArgsTable = class GroupuiArgsTable {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiControlChanged']);
    }
};
GroupuiArgsTable.decorators = [
    { type: Component, args: [{
                selector: 'groupui-args-table',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['component', 'customElementsJson'],
                outputs: ['groupuiControlChanged']
            },] },
];
/** @nocollapse */
GroupuiArgsTable.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiArgsTable = __decorate([
    ProxyCmp({
        inputs: ['component', 'customElementsJson']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiArgsTable);
let GroupuiButton = class GroupuiButton {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiButton.decorators = [
    { type: Component, args: [{
                selector: 'groupui-button',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['alignment', 'disabled', 'fullwidth', 'inverted', 'rel', 'severity', 'size', 'target', 'type', 'url', 'variant']
            },] },
];
/** @nocollapse */
GroupuiButton.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiButton = __decorate([
    ProxyCmp({
        inputs: ['alignment', 'disabled', 'fullwidth', 'inverted', 'rel', 'severity', 'size', 'target', 'type', 'url', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiButton);
let GroupuiButtonGroup = class GroupuiButtonGroup {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiButtonGroup.decorators = [
    { type: Component, args: [{
                selector: 'groupui-button-group',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['disabled', 'fullwidth', 'inverted', 'name', 'variant']
            },] },
];
/** @nocollapse */
GroupuiButtonGroup.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiButtonGroup = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'fullwidth', 'inverted', 'name', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiButtonGroup);
let GroupuiButtonGroupButton = class GroupuiButtonGroupButton {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiButtonGroupButton.decorators = [
    { type: Component, args: [{
                selector: 'groupui-button-group-button',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['checked', 'disabled', 'inverted', 'name', 'samewidth', 'value']
            },] },
];
/** @nocollapse */
GroupuiButtonGroupButton.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiButtonGroupButton = __decorate([
    ProxyCmp({
        inputs: ['checked', 'disabled', 'inverted', 'name', 'samewidth', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiButtonGroupButton);
let GroupuiCard = class GroupuiCard {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiCard.decorators = [
    { type: Component, args: [{
                selector: 'groupui-card',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['background', 'border', 'elevation', 'height', 'padding', 'selected']
            },] },
];
/** @nocollapse */
GroupuiCard.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiCard = __decorate([
    ProxyCmp({
        inputs: ['background', 'border', 'elevation', 'height', 'padding', 'selected']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiCard);
let GroupuiCheckbox = class GroupuiCheckbox {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange']);
    }
};
GroupuiCheckbox.decorators = [
    { type: Component, args: [{
                selector: 'groupui-checkbox',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['checked', 'crossed', 'disabled', 'indeterminate', 'name', 'required', 'severity', 'value'],
                outputs: ['groupuiChange']
            },] },
];
/** @nocollapse */
GroupuiCheckbox.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiCheckbox = __decorate([
    ProxyCmp({
        inputs: ['checked', 'crossed', 'disabled', 'indeterminate', 'name', 'required', 'severity', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiCheckbox);
let GroupuiCodeSnippet = class GroupuiCodeSnippet {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiCodeSnippet.decorators = [
    { type: Component, args: [{
                selector: 'groupui-code-snippet',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['selectedIndex', 'snippet', 'tabs']
            },] },
];
/** @nocollapse */
GroupuiCodeSnippet.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiCodeSnippet = __decorate([
    ProxyCmp({
        inputs: ['selectedIndex', 'snippet', 'tabs']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiCodeSnippet);
let GroupuiDatePicker = class GroupuiDatePicker {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange', 'groupuiInvalid']);
    }
};
GroupuiDatePicker.decorators = [
    { type: Component, args: [{
                selector: 'groupui-date-picker',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['immutable', 'name', 'required', 'severity', 'validateInput', 'value'],
                outputs: ['groupuiChange', 'groupuiInvalid']
            },] },
];
/** @nocollapse */
GroupuiDatePicker.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiDatePicker = __decorate([
    ProxyCmp({
        inputs: ['immutable', 'name', 'required', 'severity', 'validateInput', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiDatePicker);
let GroupuiDateRangePicker = class GroupuiDateRangePicker {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange', 'groupuiInvalid']);
    }
};
GroupuiDateRangePicker.decorators = [
    { type: Component, args: [{
                selector: 'groupui-date-range-picker',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['endValue', 'immutable', 'name', 'required', 'severity', 'startValue', 'validateInput', 'value'],
                outputs: ['groupuiChange', 'groupuiInvalid']
            },] },
];
/** @nocollapse */
GroupuiDateRangePicker.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiDateRangePicker = __decorate([
    ProxyCmp({
        inputs: ['endValue', 'immutable', 'name', 'required', 'severity', 'startValue', 'validateInput', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiDateRangePicker);
class GroupuiDivider {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
GroupuiDivider.decorators = [
    { type: Component, args: [{
                selector: 'groupui-divider',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>'
            },] },
];
/** @nocollapse */
GroupuiDivider.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
let GroupuiDropdown = class GroupuiDropdown {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange']);
    }
};
GroupuiDropdown.decorators = [
    { type: Component, args: [{
                selector: 'groupui-dropdown',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['description', 'disabled', 'index', 'label', 'maxHeight', 'name', 'placeholder', 'severity', 'size', 'value'],
                outputs: ['groupuiChange']
            },] },
];
/** @nocollapse */
GroupuiDropdown.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiDropdown = __decorate([
    ProxyCmp({
        inputs: ['description', 'disabled', 'index', 'label', 'maxHeight', 'name', 'placeholder', 'severity', 'size', 'value'],
        methods: ['updateIndex']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiDropdown);
let GroupuiDropdownOption = class GroupuiDropdownOption {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiDropdownOption.decorators = [
    { type: Component, args: [{
                selector: 'groupui-dropdown-option',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['disabled', 'value']
            },] },
];
/** @nocollapse */
GroupuiDropdownOption.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiDropdownOption = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiDropdownOption);
let GroupuiGridCol = class GroupuiGridCol {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiGridCol.decorators = [
    { type: Component, args: [{
                selector: 'groupui-grid-col',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['l', 'm', 's', 'xl', 'xs', 'xxl']
            },] },
];
/** @nocollapse */
GroupuiGridCol.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiGridCol = __decorate([
    ProxyCmp({
        inputs: ['l', 'm', 's', 'xl', 'xs', 'xxl']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiGridCol);
let GroupuiGridRow = class GroupuiGridRow {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiGridRow.decorators = [
    { type: Component, args: [{
                selector: 'groupui-grid-row',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['flexible', 'gutterL', 'gutterM', 'gutterS', 'gutterXl', 'gutterXs', 'gutterXxl']
            },] },
];
/** @nocollapse */
GroupuiGridRow.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiGridRow = __decorate([
    ProxyCmp({
        inputs: ['flexible', 'gutterL', 'gutterM', 'gutterS', 'gutterXl', 'gutterXs', 'gutterXxl']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiGridRow);
class GroupuiHeader {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
GroupuiHeader.decorators = [
    { type: Component, args: [{
                selector: 'groupui-header',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>'
            },] },
];
/** @nocollapse */
GroupuiHeader.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
let GroupuiHeadline = class GroupuiHeadline {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiHeadline.decorators = [
    { type: Component, args: [{
                selector: 'groupui-headline',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['heading', 'serif', 'weight']
            },] },
];
/** @nocollapse */
GroupuiHeadline.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiHeadline = __decorate([
    ProxyCmp({
        inputs: ['heading', 'serif', 'weight']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiHeadline);
let GroupuiIcon = class GroupuiIcon {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiIcon.decorators = [
    { type: Component, args: [{
                selector: 'groupui-icon',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['height', 'name', 'width']
            },] },
];
/** @nocollapse */
GroupuiIcon.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiIcon = __decorate([
    ProxyCmp({
        inputs: ['height', 'name', 'width']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiIcon);
let GroupuiIconStatic = class GroupuiIconStatic {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiIconStatic.decorators = [
    { type: Component, args: [{
                selector: 'groupui-icon-static',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['name', 'theme']
            },] },
];
/** @nocollapse */
GroupuiIconStatic.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiIconStatic = __decorate([
    ProxyCmp({
        inputs: ['name', 'theme']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiIconStatic);
let GroupuiInput = class GroupuiInput {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiSuggestionSelected', 'groupuiChange', 'groupuiInvalid']);
    }
};
GroupuiInput.decorators = [
    { type: Component, args: [{
                selector: 'groupui-input',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['immutable', 'maxlength', 'name', 'pattern', 'placeholder', 'required', 'severity', 'suggestions', 'suggestionsMaxHeight', 'textTransform', 'type', 'validity', 'value'],
                outputs: ['groupuiSuggestionSelected', 'groupuiChange', 'groupuiInvalid']
            },] },
];
/** @nocollapse */
GroupuiInput.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiInput = __decorate([
    ProxyCmp({
        inputs: ['immutable', 'maxlength', 'name', 'pattern', 'placeholder', 'required', 'severity', 'suggestions', 'suggestionsMaxHeight', 'textTransform', 'type', 'validity', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiInput);
let GroupuiItem = class GroupuiItem {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiItem.decorators = [
    { type: Component, args: [{
                selector: 'groupui-item',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['border', 'size']
            },] },
];
/** @nocollapse */
GroupuiItem.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiItem = __decorate([
    ProxyCmp({
        inputs: ['border', 'size']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiItem);
let GroupuiLink = class GroupuiLink {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiLink.decorators = [
    { type: Component, args: [{
                selector: 'groupui-link',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['href', 'rel', 'target']
            },] },
];
/** @nocollapse */
GroupuiLink.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiLink = __decorate([
    ProxyCmp({
        inputs: ['href', 'rel', 'target']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiLink);
class GroupuiList {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
GroupuiList.decorators = [
    { type: Component, args: [{
                selector: 'groupui-list',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>'
            },] },
];
/** @nocollapse */
GroupuiList.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
let GroupuiLoadingSpinner = class GroupuiLoadingSpinner {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiLoadingSpinner.decorators = [
    { type: Component, args: [{
                selector: 'groupui-loading-spinner',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['background', 'displayed', 'duration', 'severity', 'variant']
            },] },
];
/** @nocollapse */
GroupuiLoadingSpinner.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiLoadingSpinner = __decorate([
    ProxyCmp({
        inputs: ['background', 'displayed', 'duration', 'severity', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiLoadingSpinner);
let GroupuiModal = class GroupuiModal {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiModalClose']);
    }
};
GroupuiModal.decorators = [
    { type: Component, args: [{
                selector: 'groupui-modal',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['background', 'closeButton', 'disableBackdropClose', 'displayed', 'iconMargin', 'l', 'm', 'margin', 'maxWidth', 'padding', 's', 'xl', 'xs', 'xxl'],
                outputs: ['groupuiModalClose']
            },] },
];
/** @nocollapse */
GroupuiModal.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiModal = __decorate([
    ProxyCmp({
        inputs: ['background', 'closeButton', 'disableBackdropClose', 'displayed', 'iconMargin', 'l', 'm', 'margin', 'maxWidth', 'padding', 's', 'xl', 'xs', 'xxl']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiModal);
let GroupuiMultiselect = class GroupuiMultiselect {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange']);
    }
};
GroupuiMultiselect.decorators = [
    { type: Component, args: [{
                selector: 'groupui-multiselect',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['description', 'disabled', 'label', 'maxHeight', 'placeholder', 'severity'],
                outputs: ['groupuiChange']
            },] },
];
/** @nocollapse */
GroupuiMultiselect.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiMultiselect = __decorate([
    ProxyCmp({
        inputs: ['description', 'disabled', 'label', 'maxHeight', 'placeholder', 'severity']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiMultiselect);
let GroupuiMultiselectOption = class GroupuiMultiselectOption {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiMultiselectOption.decorators = [
    { type: Component, args: [{
                selector: 'groupui-multiselect-option',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['checked', 'value']
            },] },
];
/** @nocollapse */
GroupuiMultiselectOption.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiMultiselectOption = __decorate([
    ProxyCmp({
        inputs: ['checked', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiMultiselectOption);
let GroupuiNavigationItem = class GroupuiNavigationItem {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiNavigationItem.decorators = [
    { type: Component, args: [{
                selector: 'groupui-navigation-item',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['disabled', 'href', 'inverted', 'size', 'target']
            },] },
];
/** @nocollapse */
GroupuiNavigationItem.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiNavigationItem = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'href', 'inverted', 'size', 'target']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiNavigationItem);
let GroupuiNotification = class GroupuiNotification {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiClose']);
    }
};
GroupuiNotification.decorators = [
    { type: Component, args: [{
                selector: 'groupui-notification',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['dismissible', 'severity', 'variant'],
                outputs: ['groupuiClose']
            },] },
];
/** @nocollapse */
GroupuiNotification.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiNotification = __decorate([
    ProxyCmp({
        inputs: ['dismissible', 'severity', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiNotification);
let GroupuiPagination = class GroupuiPagination {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['pageChange']);
    }
};
GroupuiPagination.decorators = [
    { type: Component, args: [{
                selector: 'groupui-pagination',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['noBorder', 'page', 'pageSize', 'pageSizeOptions', 'totalElements'],
                outputs: ['pageChange']
            },] },
];
/** @nocollapse */
GroupuiPagination.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiPagination = __decorate([
    ProxyCmp({
        inputs: ['noBorder', 'page', 'pageSize', 'pageSizeOptions', 'totalElements']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiPagination);
let GroupuiProgressCircle = class GroupuiProgressCircle {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiProgressCircle.decorators = [
    { type: Component, args: [{
                selector: 'groupui-progress-circle',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['background', 'displayed', 'progress', 'severity', 'variant']
            },] },
];
/** @nocollapse */
GroupuiProgressCircle.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiProgressCircle = __decorate([
    ProxyCmp({
        inputs: ['background', 'displayed', 'progress', 'severity', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiProgressCircle);
let GroupuiRadioButton = class GroupuiRadioButton {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiRadioButton.decorators = [
    { type: Component, args: [{
                selector: 'groupui-radio-button',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['checked', 'disabled', 'severity', 'value']
            },] },
];
/** @nocollapse */
GroupuiRadioButton.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiRadioButton = __decorate([
    ProxyCmp({
        inputs: ['checked', 'disabled', 'severity', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiRadioButton);
let GroupuiRadioGroup = class GroupuiRadioGroup {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange']);
    }
};
GroupuiRadioGroup.decorators = [
    { type: Component, args: [{
                selector: 'groupui-radio-group',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['disabled', 'name', 'value'],
                outputs: ['groupuiChange']
            },] },
];
/** @nocollapse */
GroupuiRadioGroup.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiRadioGroup = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'name', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiRadioGroup);
let GroupuiSearchField = class GroupuiSearchField {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiSearchStarted', 'groupuiSuggestionSelected', 'groupuiChange', 'groupuiClearClicked']);
    }
};
GroupuiSearchField.decorators = [
    { type: Component, args: [{
                selector: 'groupui-search-field',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['autofocus', 'placeholder', 'suggestionsMaxHeight', 'value'],
                outputs: ['groupuiSearchStarted', 'groupuiSuggestionSelected', 'groupuiChange', 'groupuiClearClicked']
            },] },
];
/** @nocollapse */
GroupuiSearchField.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiSearchField = __decorate([
    ProxyCmp({
        inputs: ['autofocus', 'placeholder', 'suggestionsMaxHeight', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiSearchField);
class GroupuiSearchSuggestion {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
GroupuiSearchSuggestion.decorators = [
    { type: Component, args: [{
                selector: 'groupui-search-suggestion',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>'
            },] },
];
/** @nocollapse */
GroupuiSearchSuggestion.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
let GroupuiShowcase = class GroupuiShowcase {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiShowcase.decorators = [
    { type: Component, args: [{
                selector: 'groupui-showcase',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['components', 'customElementsJsonPath']
            },] },
];
/** @nocollapse */
GroupuiShowcase.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiShowcase = __decorate([
    ProxyCmp({
        inputs: ['components', 'customElementsJsonPath']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiShowcase);
let GroupuiSideNavigation = class GroupuiSideNavigation {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiSideNavigation.decorators = [
    { type: Component, args: [{
                selector: 'groupui-side-navigation',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['inverted']
            },] },
];
/** @nocollapse */
GroupuiSideNavigation.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiSideNavigation = __decorate([
    ProxyCmp({
        inputs: ['inverted']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiSideNavigation);
let GroupuiSwitch = class GroupuiSwitch {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange']);
    }
};
GroupuiSwitch.decorators = [
    { type: Component, args: [{
                selector: 'groupui-switch',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['checked', 'disabled', 'name', 'value', 'variant'],
                outputs: ['groupuiChange']
            },] },
];
/** @nocollapse */
GroupuiSwitch.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiSwitch = __decorate([
    ProxyCmp({
        inputs: ['checked', 'disabled', 'name', 'value', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiSwitch);
let GroupuiTab = class GroupuiTab {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiTab.decorators = [
    { type: Component, args: [{
                selector: 'groupui-tab',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['name', 'tab']
            },] },
];
/** @nocollapse */
GroupuiTab.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTab = __decorate([
    ProxyCmp({
        inputs: ['name', 'tab'],
        methods: ['setActive']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTab);
let GroupuiTable = class GroupuiTable {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiTable.decorators = [
    { type: Component, args: [{
                selector: 'groupui-table',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['columns', 'header', 'indicator', 'size', 'sortable', 'sticky', 'striped', 'widths']
            },] },
];
/** @nocollapse */
GroupuiTable.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTable = __decorate([
    ProxyCmp({
        inputs: ['columns', 'header', 'indicator', 'size', 'sortable', 'sticky', 'striped', 'widths']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTable);
let GroupuiTableCell = class GroupuiTableCell {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiSortClick']);
    }
};
GroupuiTableCell.decorators = [
    { type: Component, args: [{
                selector: 'groupui-table-cell',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['alignment', 'colspan', 'highlighted', 'indicator', 'order', 'sortable'],
                outputs: ['groupuiSortClick']
            },] },
];
/** @nocollapse */
GroupuiTableCell.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTableCell = __decorate([
    ProxyCmp({
        inputs: ['alignment', 'colspan', 'highlighted', 'indicator', 'order', 'sortable']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTableCell);
let GroupuiTabs = class GroupuiTabs {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiTabChange']);
    }
};
GroupuiTabs.decorators = [
    { type: Component, args: [{
                selector: 'groupui-tabs',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['activeTabId', 'equalWidth', 'size'],
                outputs: ['groupuiTabChange']
            },] },
];
/** @nocollapse */
GroupuiTabs.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTabs = __decorate([
    ProxyCmp({
        inputs: ['activeTabId', 'equalWidth', 'size'],
        methods: ['select', 'getSelected']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTabs);
let GroupuiTag = class GroupuiTag {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiTag.decorators = [
    { type: Component, args: [{
                selector: 'groupui-tag',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['disabled', 'severity', 'size', 'square', 'variant']
            },] },
];
/** @nocollapse */
GroupuiTag.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTag = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'severity', 'size', 'square', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTag);
let GroupuiText = class GroupuiText {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiText.decorators = [
    { type: Component, args: [{
                selector: 'groupui-text',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['size', 'weight']
            },] },
];
/** @nocollapse */
GroupuiText.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiText = __decorate([
    ProxyCmp({
        inputs: ['size', 'weight']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiText);
let GroupuiTextarea = class GroupuiTextarea {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['groupuiChange', 'groupuiInvalid']);
    }
};
GroupuiTextarea.decorators = [
    { type: Component, args: [{
                selector: 'groupui-textarea',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['autoExpand', 'immutable', 'name', 'placeholder', 'required', 'rows', 'severity', 'value'],
                outputs: ['groupuiChange', 'groupuiInvalid']
            },] },
];
/** @nocollapse */
GroupuiTextarea.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTextarea = __decorate([
    ProxyCmp({
        inputs: ['autoExpand', 'immutable', 'name', 'placeholder', 'required', 'rows', 'severity', 'value']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTextarea);
let GroupuiTooltip = class GroupuiTooltip {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiTooltip.decorators = [
    { type: Component, args: [{
                selector: 'groupui-tooltip',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['position', 'variant']
            },] },
];
/** @nocollapse */
GroupuiTooltip.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTooltip = __decorate([
    ProxyCmp({
        inputs: ['position', 'variant']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTooltip);
let GroupuiTopNavigation = class GroupuiTopNavigation {
    /**
     * @param {?} c
     * @param {?} r
     * @param {?} z
     */
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
GroupuiTopNavigation.decorators = [
    { type: Component, args: [{
                selector: 'groupui-top-navigation',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: '<ng-content></ng-content>',
                inputs: ['inverted']
            },] },
];
/** @nocollapse */
GroupuiTopNavigation.ctorParameters = () => [
    { type: ChangeDetectorRef },
    { type: ElementRef },
    { type: NgZone }
];
GroupuiTopNavigation = __decorate([
    ProxyCmp({
        inputs: ['inverted']
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
], GroupuiTopNavigation);

/**
 * @fileoverview added by tsickle
 * Generated from: group-ui.module.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingRequire,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
defineCustomElements(window);
/** @type {?} */
const DECLARATIONS = [
    // proxies
    GroupuiAccordion,
    GroupuiButton,
    GroupuiButtonGroup,
    GroupuiButtonGroupButton,
    GroupuiCard,
    GroupuiCheckbox,
    GroupuiDatePicker,
    GroupuiDateRangePicker,
    GroupuiDivider,
    GroupuiDropdown,
    GroupuiGridCol,
    GroupuiGridRow,
    GroupuiDropdownOption,
    GroupuiHeader,
    GroupuiHeadline,
    GroupuiIcon,
    GroupuiInput,
    GroupuiItem,
    GroupuiLink,
    GroupuiList,
    GroupuiLoadingSpinner,
    GroupuiModal,
    GroupuiNotification,
    GroupuiPagination,
    GroupuiProgressCircle,
    GroupuiRadioButton,
    GroupuiRadioGroup,
    GroupuiSearchField,
    GroupuiSearchSuggestion,
    GroupuiSideNavigation,
    GroupuiSwitch,
    GroupuiTable,
    GroupuiTableCell,
    GroupuiTab,
    GroupuiTabs,
    GroupuiTag,
    GroupuiText,
    GroupuiTextarea,
    GroupuiTooltip,
    GroupuiTopNavigation,
    GroupuiNavigationItem,
    GroupuiMultiselect,
    GroupuiMultiselectOption,
];
class GroupUiModule {
}
GroupUiModule.decorators = [
    { type: NgModule, args: [{
                declarations: DECLARATIONS,
                exports: DECLARATIONS,
                imports: [],
                providers: [],
            },] },
];

export { GroupUiModule, GroupuiAccordion, GroupuiArgsTable, GroupuiButton, GroupuiButtonGroup, GroupuiButtonGroupButton, GroupuiCard, GroupuiCheckbox, GroupuiCodeSnippet, GroupuiDatePicker, GroupuiDateRangePicker, GroupuiDivider, GroupuiDropdown, GroupuiDropdownOption, GroupuiGridCol, GroupuiGridRow, GroupuiHeader, GroupuiHeadline, GroupuiIcon, GroupuiIconStatic, GroupuiInput, GroupuiItem, GroupuiLink, GroupuiList, GroupuiLoadingSpinner, GroupuiModal, GroupuiMultiselect, GroupuiMultiselectOption, GroupuiNavigationItem, GroupuiNotification, GroupuiPagination, GroupuiProgressCircle, GroupuiRadioButton, GroupuiRadioGroup, GroupuiSearchField, GroupuiSearchSuggestion, GroupuiShowcase, GroupuiSideNavigation, GroupuiSwitch, GroupuiTab, GroupuiTable, GroupuiTableCell, GroupuiTabs, GroupuiTag, GroupuiText, GroupuiTextarea, GroupuiTooltip, GroupuiTopNavigation, ProxyCmp as ɵa };
